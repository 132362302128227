import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import anime from 'animejs';

import { Link } from '../Link';

class Component extends React.Component {
  static displayName = 'Brand';

  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    link: PropTypes.string,
    hover: PropTypes.bool,
    stableTime: PropTypes.bool,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onLinkStart: PropTypes.func,
    onLinkEnd: PropTypes.func
  };

  static defaultProps = {
    link: '/'
  };

  constructor () {
    super(...arguments);

    const { energy, stableTime } = this.props;

    if (!stableTime) {
      energy.updateDuration({ enter: 820 });
    }
  }

  componentWillUnmount () {
    const paths = this.svgElement.querySelectorAll('path');
    anime.remove(paths);
  }

  enter () {
    const { energy, sounds, stableTime, onEnter } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    anime.set(this.svgElement, { opacity: 1 });

    sounds.logo.play();

    anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      delay: (path, index) => stableTime ? 0 : index * energy.duration.stagger,
      duration: path => stableTime ? energy.duration.enter : path.getTotalLength(),
      complete: () => {
        onEnter && onEnter();
      }
    });
  }

  exit () {
    const { energy, sounds, onExit } = this.props;
    const paths = this.svgElement.querySelectorAll('path');

    sounds.fade.play();

    anime({
      targets: this.svgElement,
      easing: 'easeInCubic',
      duration: energy.duration.exit,
      opacity: 0
    });
    anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      direction: 'reverse',
      duration: energy.duration.exit,
      complete: () => {
        anime.set(this.svgElement, { opacity: 0 });
        onExit && onExit();
      }
    });
  }

  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      link,
      hover,
      stableTime,
      onEnter,
      onExit,
      onLinkStart,
      onLinkEnd,
      ...etc
    } = this.props;

    return (
      <h1 className={cx(classes.root, hover && classes.hover, className)} {...etc}>
        <Link
          className={classes.link}
          href={link}
          title='Ramadan logo'
          onLinkStart={onLinkStart}
          onLinkEnd={onLinkEnd}>

          <span className={classes.title}>Ramadan</span>
          <svg
            ref={ref => (this.svgElement = ref)}
            className={classes.svg}
            viewBox='0 0 1400 92'
            xmlns='http://www.w3.org/2000/svg'
            onMouseEnter={() => sounds.hover.play()}
          >
            <path className={classes.path} d='M265.2,2a30.1,30.1,0,1,1,0,60.1h-7.8l38,27.4H277L238.9,62.1H197V89.5H186V51.2h79.2a19.2,19.2,0,1,0,0-38.3H186V2Z'  />
            <path className={classes.path} d='M396.7,2l48.7,87.5H432.8L390.7,13.9l-42,75.6H336.1L384.8,2Z' />
            <path className={classes.path} d='M503.4,2l43.7,32.8H549L592.7,2h10V89.5H591.9V16.4L552.6,45.8h-9.1L504.4,16.4V89.5h-11V2Z' />
            <path className={classes.path} d='M708.2,2l48.7,87.5H744.3L702.2,13.9l-42,75.6H647.6L696.3,2Z' />
            <path className={classes.path} d='M802,2h84.8a24.6,24.6,0,0,1,24.6,24.6V64.9a24.6,24.6,0,0,1-24.6,24.6H802Zm11,76.5h73.8a13.6,13.6,0,0,0,13.7-13.6V26.6a13.7,13.7,0,0,0-13.7-13.7H813Z' />
            <path className={classes.path} d='M1010.8,2l48.8,87.5h-12.7l-42.1-75.6-42,75.6H950.2L998.9,2Z' />
            <path className={classes.path} d='M1104.6,2h10.2l88.3,72.8V2H1214V89.5h-10.1l-88.3-72.7V89.5h-11Z' />

          </svg>
        </Link>
      </h1>
    );
  }
}

export { Component };
