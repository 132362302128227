import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import anime from 'animejs';

import { Text } from '../Text';
import { getPathLength } from '../../tools/general';





class Component extends React.Component {
  static displayName = 'Cyberbtn';

  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    children: PropTypes.string.isRequired
  };

  // glitchElement @keyframes

  keyFrames = 
    [
      {clipPath: 'inset(80% -4px 0 0)',    translateX: 0 , translateY: 5},
      {clipPath: 'inset(1% -4px 60% 0)',  translateX: 1 , translateY: 10},
      {clipPath: 'inset(80% -4px 0 0)',    translateX: -2 , translateY: 4},
      {clipPath: 'inset(1% -4px 60% 0)',  translateX: 0 , translateY: 15},
      {clipPath: 'inset(50% -4px 30% 0)',  translateX: 1 , translateY: -2},
      {clipPath: 'inset(1% -4px 60% 0)',  translateX: 1 , translateY: 12},
      {clipPath: 'inset(40% -4px 43% 0)',  translateX: 8 , translateY: 10},
      {clipPath: 'inset(50% -4px 30% 0)',  translateX: -8 , translateY: 8},
      {clipPath: 'inset(1% -4px 60% 0)',  translateX: 1 , translateY: 10},
      {clipPath: 'inset(80% -4px 0 0)',    translateX: -2 , translateY: 4},
      {clipPath: 'inset(1% -4px 60% 0)',  translateX: 0 , translateY: 15},
      {clipPath: 'inset(50% -4px 30% 0)',  translateX: 1 , translateY: -2},
      {clipPath: 'inset(1% -4px 60% 0)',  translateX: 1 , translateY: 12},
      {clipPath: 'inset(80% -4px 5% 0)',   translateX: 10 , translateY: -1},
      {clipPath: 'inset(80% -4px 0 0)',    translateX: -5 , translateY: 0},
      {clipPath: 'inset(80% -4px 0 0)',    translateX: 1 , translateY: 0},
      {clipPath: 'inset(50% 50% 50% 50%)'}
    ];
  
  glitches () {

    anime({
      targets: this.glitchElement,
      loop: false,
      duration: 1000,
      easing: 'steps(1, end)',
      background:'linear-gradient(45deg, transparent 5%, #b71c1c 5%)', 
      keyframes: this.keyFrames
    });

  };

  enter () {
    const { energy } = this.props;
    this.glitches;

    anime.set(this.btnElement, {
      opacity: 1,
    });
    anime.set(this.glitchElement, { opacity: 1 });

    anime({
      targets: this.glitchElement,
      easing: 'linear',
      opacity: [0, 1],
      duration: energy.duration.enter
    });
  }

  exit () {
    const { energy } = this.props;
    anime({
      targets: this.glitchElement,
      easing: 'linear',
      opacity: [1, 0],
      duration: energy.duration.enter
    });
  }




  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      children,
      glitches,
      ...etc
    } = this.props;

    return (
      <button
        className={cx(classes.root, className) }
        {...etc}
        ref={ref => (this.btnElement = ref)}
        onMouseEnter={() => this.glitches()}  
      > 
        <div className={classes.glitch} ref={ref => (this.glitchElement = ref)}>
        {children}
       </div>
        <div
          className={classes.main}
          onMouseOver={() => sounds.hover && sounds.hover.play()}
        >
          <Text
            audio={audio}
            animation={{ animate: energy.animate }}
            
          >
            {children}
          </Text>
        </div>
      </button>
    );
  }
}

export { Component };
