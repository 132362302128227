import { rgba } from 'polished';

const styles = theme => ({
    root: {
      position: 'relative',
      display: 'inline-block',
      margin: 0,
      border: 'none',
      outline: 'none',
      padding: 0,
      textTransform: 'uppercase',
      textAlign: 'center',
      lineHeight: 1,
      fontSize: 14,
      fontFamily: "Electrolize, sans-serif", 
      background: "linear-gradient(45deg, transparent 5%, #27e1fa20 5%)", 
      border: "0", 
      color: "#27e1fa", 
      letterSpacing: "2px", 
      boxShadow: "2px 0px 0px #00E6F6", 
      outline: "transparent", 
      position: "relative",
      cursor: 'pointer',
      
    

      '&:hover, &:focus': {

        background: "linear-gradient(45deg, transparent 5%, #fa2727b5 5%)",
      }  
        
    
    },

    glitch: {
      width: "100%",
      height: "100%",
      textShadow: "-1px -1px 0px #F8F005, 1px 1px 0px #00E6F6", 
      display: "block", 
      position: "absolute", 
      top: "0", 
      left: "0", 
      right: "0", 
      bottom: "0", 
      background: "linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #27e1fa20 5%)", 
      clipPath: "inset(50% 50% 50% 50%)",

    },

    main: {
      position: 'relative',
      padding: [12, 20],
      
    },

    '@media screen and (max-width: 480px)': {
      root: {
        width: "100%",
        background: "linear-gradient(40deg, transparent 3%, #27e1fa20 3%)",

        '&:hover, &:focus': {

          background: "linear-gradient(40deg, transparent 3%, #fa2727b5 3%)",
        }  

      },

      glitch: {
        background: "linear-gradient(40deg, transparent 3%, #fa2727b5 3%)"
      }

    },


    
});

export { styles };
